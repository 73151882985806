// Variables
@import "../../../sass/variables";

#header-search-text {
  border: 1px solid #878787;
  border-radius: 50vh;
  font-size: 14px;
  padding-left: 25px !important;
}

header {
  // ヘッダー最上部帯エリア
  .header-top-belt {
    background: black;
    color: white;
    padding: 5px 15px;
    font-size: 12px;
  }

  // ヘッダー全体
  .header-nav-wrapper {
    background-color: $theme-header-green;
    box-shadow: 0 0 8px 0 $shadow;
    margin: 0;
    padding: 0;
  }


  #nav-pc-scrolled {
    // ツールチップ
    .header-tooltip {
      position: relative;
      cursor: pointer;
      display: inline-block;

      .description {
        display: none;
        position: absolute;
        padding: 7px 20px;
        font-size: 12px;
        line-height: 1.6em;
        color: white;
        border-radius: 5px;
        background: #000;
        width: max-content;
        text-align: center;
        z-index: 9;
      }

      .description:before {
        content: "";
        position: absolute;
        top: -85%;
        left: 50%;
        border: 15px solid transparent;
        border-top: 15px solid #000;
        margin-left: -15px;
        transform: rotateZ(180deg);
      }

      :hover > .description {
        display: inline-block;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
      }
    }

    .message-balloon {
      position: absolute;
      top: 88px;
      right: 0;
      border-radius: 5px;
      padding: 3px 15px;
      min-width: 270px;
      max-width: 100%;
      font-weight: bold;
      font-size: 16px;
      background: #fff;
      border: solid 2px red;
      z-index: 6;

      :before {
        content: "";
        position: absolute;
        top: -50px;
        left: 45%;
        margin-left: -15px;
        border: 12px solid transparent;
        border-bottom: 41px solid #FFF;
        z-index: 2;
      }

      :after {
        content: "";
        position: absolute;
        top: -54px;
        right: 136px;
        margin-left: -18px;
        border: 13px solid transparent;
        border-bottom: 41px solid red;
        z-index: 1;
      }

      a {
        color: red!important;
      }

      .balloon-close-icon {
        cursor: pointer;
        font-size: 20px;
        color: red!important;

        :hover {
          opacity: 1 !important;
        }
      }
    }
  }

  .header-pc {
    padding-bottom: 7px;

    .header-logo {
      margin-top: 0.4rem;
      margin-left: 1rem;
      display: inline-block;
    }

    .header-right-icon {
      .menu-bottom {
        a {
          color: white;
          margin-right: 30px;
          font-size: 0.9rem;
          cursor: pointer;
        }

        img {
          margin-right: 5px;
          margin-bottom: 3px;
          width: 20px;
          height: 20px;
        }
      }
    }

    // ツールチップ
    .header-tooltip {
      position: relative;
      cursor: pointer;
      display: inline-block;

      .description {
        display: none;
        position: absolute;
        padding: 7px 20px;
        font-size: 12px;
        line-height: 1.6em;
        color: #fff;
        border-radius: 5px;
        background: #000;
        width: max-content;
        text-align: center;
      }

      .description:before {
        content: "";
        position: absolute;
        top: -85%;
        left: 50%;
        border: 15px solid transparent;
        border-top: 15px solid #000;
        margin-left: -15px;
        transform: rotateZ(180deg);
      }

      :hover > .description {
        display: inline-block;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
      }
    }

    .message-balloon {
      position: absolute;
      top: 95px;
      right: 0;
      border-radius: 5px;
      padding: 3px 15px;
      min-width: 280px;
      max-width: 100%;
      font-weight: bold;
      font-size: 16px;
      background: #fff;
      border: solid 2px red;
      z-index: 6;

      :before {
        content: "";
        position: absolute;
        top: -47px;
        left: 49%;
        margin-left: -15px;
        border: 12px solid transparent;
        border-bottom: 41px solid #FFF;
        z-index: 2;
      }

      :after {
        content: "";
        position: absolute;
        top: -54px;
        right: 47%;
        margin-left: -18px;
        border: 13px solid transparent;
        border-bottom: 41px solid red;
        z-index: 1;
      }

      a {
        color: red!important;
      }

      .balloon-close-icon {
        cursor: pointer;
        font-size: 20px;
        color: red!important;

        :hover {
          opacity: 1 !important;
        }
      }
    }
  }

  // PCスクロール用スタイル
  #nav-pc-scrolled {
    position: fixed;
    top: 0;
    width: 100%;
    color: white;
    background-color: $theme-header-green;
    z-index: 10;
    padding-bottom: 5px;
    box-shadow: 0 0 8px 0 rgb(128 128 128 / 50%);

    .header-pc-scrolled {
      .header-logo {
        margin-top: 0.3rem;
      }

      .third-bar {
        font-size: 14px;

        .row {
          .custom-nav-link {
            color: $theme-main-white;
            padding-bottom: 1px;
          }
        }
      }
    }
  }

  .nav-sp {
    display: none;
  }

  .fixed-header {
    //display: none;
    //pointer-events: none;
  }

  // 一列目
  .top-bar {
    background-color: $theme-main-graywhite;
    font-size: 0.9rem;

    .row {
      margin: 0 auto;
      padding: 5px 10px;
    }
  }

  // 二列目
  .second-bar {
    .row {
      margin: 5px auto;
    }

    .header-category {
      color: #fafafa;
      background-color: #878787;
      width: 95%;
      text-align: center;
      height: 3rem;
      border: none !important;
      border-top-left-radius: 50vh !important;
      border-bottom-left-radius: 50vh !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding-left: 10px;
      padding-right: 5px;
    }
  }

  // 三列目
  .third-bar {
    font-size: 1.1rem;

    .row {
      margin: 0 auto;
      padding-bottom: 0;
    }

    .header-icon {
      margin-right: 5px;
    }

    .menu-bottom {
      cursor: pointer;
      position: relative;
      padding: 3px 10px 0;
      display: flex;
      align-items: center;
    }

    .menu-bottom-bar-on {
      .menu-bottom-bar {
        position: absolute;
        bottom: -5px;
        width: 100%;
        left: 0;
        height: 5px;
        background-color: $theme-accent-yellow;
      }
    }

    .menu-bottom::after {
      content: '';
      position: absolute;
      bottom: -5px;
      width: 100%;
      left: 0;
      height: 5px;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      background-color: $theme-accent-yellow;
      -webkit-transition: all .3s;
      transition: all .3s;
    }

    .menu-bottom:hover::after {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  .search-item-area, .scroll-search-item-area {
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: -1;
    padding: 15px 7%;

    .header-left-content {
      p {
        font-size: 16px;
        color: $theme-accent-gray;
      }

      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 0;

        li {
          width: 50%;
          list-style: none;
          font-size: 14px;

          a {
            cursor: pointer;
          }

          a:hover {
            color: $theme-accent-lightgray;
          }
        }
      }
    }

    .header-right-content {
      p {
        font-size: 16px;
        color: $theme-accent-gray;
      }

      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 0;


        li {
          width: 33.3%;
          list-style: none;
          font-size: 14px;

          a {
            cursor: pointer;
          }

          a:hover {
            color: $theme-accent-lightgray;
          }
        }
      }
    }
  }
}

// 丸囲み白抜き文字　Pマーク
.circle-letter {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $theme-accent-lightgray;
  color: $theme-accent-white;
  text-align: center;
  line-height: 1.5rem;
  align-self: center;
}

// ログアウトボタン
.btn-logout {
  width: 8rem;

  &:hover {
    color: $theme-accent-white
  }
}

// ナビアイコン
.nav-icon {
  font-size: 2rem;
}

// 検索エリアはimportant指定すると消えないのでflexをcssで設定
#header-search-area,
#header-search-area-scrolled {
  margin-top: 1em;

  .header-search-form {
    display: flex;

    dt {
      text-align: right;

      button {
        height: 3rem;
      }
    }

    dd {
      text-align: left;
    }
  }
}

// 検索カテゴリ設定？　ボタン
#search-category,
#search-category-scrolled {
  color: $theme-accent-white;
  background-color: #878787;
  border-top-left-radius: 50vh;
  border-bottom-left-radius: 50vh;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:focus {
    box-shadow: none;
  }
}

#search-category-scrolled {
  height: 2.2rem;
  padding-top: 5px;
}


// 検索テキスト
.search-text,
.search-text-scrolled {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;

  &:focus {
    box-shadow: none !important;
  }
}

.search-text {
  height: 3rem;
}

.search-text-scrolled {
  height: 2.2rem;
}

// 検索実行iconボタン
#search-button {
  border-radius: 50%;
  font-size: 1.3rem;
  top: 0;
  right: 5px;

  &:focus {
    box-shadow: none;
  }
}

// 検索実行iconボタンスクロール用
#search-button-scrolled {
  border-radius: 50%;
  font-size: 0.6rem;
  top: 0;
  right: 2px;

  &:focus {
    box-shadow: none;
  }
}

.header-right-icon {
  a {
    margin-right: 12px;
  }

  img {
    width: 27px;
    height: 25px;
  }

  img:hover {
    opacity: 0.5;
  }

  img:hover ~ .badge-icon {
    background-color: #ff6262;
  }
}

/////////////////////////////////////////////////
/////////////// タブレット対応 ///////////////
@media (max-width: 1020px) and (min-width: 768px) {
  // 上部固定メニュー
  .header-pc-menu {
    padding: 0 30px;

    .header-logo {
      margin-top: 1rem !important;
    }

    .third-bar {
      font-size: 14px !important;
    }
  }

  // スクロールメニュー
  .header-pc-scrolled {
    z-index: 10;
    padding: 0 30px;

    .header-logo {
      margin-top: 0.5rem !important;
    }

    .third-bar {
      font-size: 14px !important;
    }
  }

  .header-search-form {
    margin-bottom: 0;
  }

  .header-right-icon {
    a {
      margin-right: 5px !important;
    }

    img {
      width: 30px;
    }
  }

  // ナビアイコン
  .third-bar {
    margin-top: -15px;

    .header-icon {
      margin-right: 4px;
      width: 16px;
    }
  }
}


/////////////////////////////////////////////////
/////////////// タブレット対応 ///////////////////
@media (max-width: 1078px) and (min-width: 768px) {
  header {
    .third-bar {
      .menu-bottom {
        padding: 0 !important;
      }
    }
  }
}

/////////////////////////////////////////////////
/////////////// スマートフォン対応 ///////////////
@media (max-width: 767px) {
  .fixed-header {
    position: fixed !important;
    display: block !important;
    top: 0;
    width: 100%;
    z-index: 5;
    box-shadow: 0 0 8px 0 $shadow !important;
  }
  .block-header {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    background-color: white;
  }

  header {
    width: 100%;
    z-index: 20;

    .header-nav-wrapper {
      box-shadow: none !important;
      padding-bottom: 0 !important;
    }

    .header-sp {
      width: 100%;
      height: 60px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 8px 10px;
      background-color: $theme-header-green;
      z-index: 50;
    }

    .third-bar {
      .menu-bottom {
        padding: 0 !important;
      }
    }

    .nav-pc {
      display: none;
    }

    .nav-sp {
      display: block;
    }

    // 検索カテゴリ設定？　ボタン
    #search-category {
      height: 2.5rem;
    }

    // 検索テキスト
    .search-text {
      height: 2.5rem;
    }

    // 検索実行iconボタン
    #search-button {
      border-radius: 50%;
      font-size: 1.1rem;
      top: 0;
      right: 5px;

      &:focus {
        box-shadow: none;
      }
    }

    // 左上ハンバーガーメニュー　クローズ用
    .menu-btn-close {
      display: flex;
      height: 32px;
      width: 32px;
      justify-content: center;
      align-items: center;
    }

    .menu-btn-close span,
    .menu-btn-close span:after {
      content: "";
      display: block;
      height: 3px;
      width: 25px;
      border-radius: 3px;
      background-color: $theme-accent-gray;
      position: absolute;
    }

    .menu-btn-close span {
      transform: rotate(45deg);
    }

    .menu-btn-close span:after {
      transform: rotate(90deg);
    }

    .hamburger-menu-content {
      width: 90%;
      height: 100%;
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 80;
      background-color: $theme-accent-white;
      text-align: left;
      transition: 0.5s; /*アニメーション設定*/
      padding: 10px 10px 30px;
      overflow: scroll;
    }

    .hamburger-menu-content ul {
      padding: 10px 20px 0;
    }

    .hamburger-menu-content ul li {
      list-style: none;
    }

    .hamburger-menu-content ul li a {
      display: block;
      width: fit-content;
      font-size: 15px;
      box-sizing: border-box;
      color: $theme-accent-gray;
      text-decoration: none;
      padding: 9px 15px 10px 0;
      position: relative;
    }

    .hamburger-menu-content ul li a::before {
    //   content: "";
      width: 7px;
      height: 7px;
      border-top: solid 2px $theme-accent-white;
      border-right: solid 2px $theme-accent-white;
      transform: rotate(45deg);
      position: absolute;
      right: 11px;
      top: 16px;
    }

    #menu-btn-check:checked ~ .hamburger-menu-content {
      left: 0;
    }

    .hamburger-menu-search-item {
      word-break: keep-all;
      padding: 0 !important;

      .accordion-title {
        background-image: url(/assets/icons/arrow_r.png);
        background-repeat: no-repeat;
        background-position: 97% center;
        background-size: 11px;
        width: 100%;
      }

      .accordion-title.active {
        background-image: url("/assets/icons/arrow_d.png");
      }

      a:not(.accordion-title) {
        display: inline-block !important;
        padding: 0 15px !important;
        border: 1px solid #aaaaaa !important;
        border-radius: 25px !important;
        margin-bottom: 10px !important;
        width: auto !important;
      }
    }

    // カーテンメニュー用ボタン
    .btn-cartain-menu {
      font-size: 0.7rem;
      white-space: nowrap;
      width: 7rem;
    }

    // 検索ダイアログメニュー
    .search-icon-delete {
      position: absolute;
      background-color: $theme-header-green;
      height: 58px;
      width: 60px;
      z-index: 11;
      top: -15px;
      left: -15px;
      text-align: center;
    }

    .search-dialog {
      position: absolute;
      background: white;
      width: 100%;
      padding: 20px;
      z-index: 11;
      max-height: 65vh;
      overflow: scroll;

      // アコーディオン
      .accordion {
        ul {
          li {
            .accordion-title {
              background-image: url("/assets/icons/arrow_r.png");
              background-repeat: no-repeat;
              background-position: 97% center;
              background-size: 11px;
              font-size: 14px;
              background-color: #F6F6F6F6;
              padding: 8px 40px 8px 15px;
              margin-bottom: 15px;
            }

            .accordion-title.active {
              background-image: url("/assets/icons/arrow_d.png");
            }

            ul {
              word-break: keep-all;

              a {
                display: inline-block;
                padding: 0 10px;
                border: 1px solid #aaaaaa;
                border-radius: 25px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .message-balloon {
      position: absolute;
      top: 55px;
      left: 0;
      border-radius: 5px;
      padding: 0 15px;
      min-width: 295px;
      font-weight: bold;
      font-size: 16px;
      background: #fff;
      border: solid 1px red;
      z-index: 6;

      a {
        color: red!important;
      }

      .balloon-close-icon {
        cursor: pointer;
        font-size: 20px;
        color: red!important;

        :hover {
          opacity: 1 !important;
        }
      }
    }
  }
}
